<style lang="scss">
@import "@scss/app.scss";
</style>

<script>
import _ from "lodash";
import arrow from "./../../img/arrow right.svg";
import check from "./../../img/icon check checkmark done.svg";
import progress from "./../../img/wait hourglass time.svg";
import warning from "./../../img/warning exclamation mark.svg";
import add from "./../../img/icon add plus.svg";
import SVGInjector from "svg-injector";

import flag_fr from "./../../img/flags/fr.svg";
import flag_en from "./../../img/flags/en.svg";
import flag_de from "./../../img/flags/de.svg";
import flag_nl from "./../../img/flags/nl.svg";
import flag_es from "./../../img/flags/es.svg";

export default {
  data: function () {
    return {
      filter: "",
      query: "",
      arrow,
      check,
      progress,
      warning,
      add
    };
  },
  computed: {
    projects() {
      let projects = this.$store.state.projects;
      if (this.query.length) {
        return _.filter(projects, project => {
          let name = project.name.toLowerCase();
          return name.indexOf(this.query.toLowerCase()) >= 0;
        });
      }
      return projects;
    },
    cantGoBack() {
      return this.currentPage === 0;
    },
  },
  methods: {
    getActiveTranslations(translations) {
      return _.filter(
        translations,
        translation => translation.status !== "inactive"
      );
    },
    getProjectProgress(project) {
      if (project && project.episodes) {
        let doneEpisodes = 0;
        _.forEach(project.episodes, episode => {
          if (episode.status == "COMPLETED") {
            doneEpisodes++;
          }
        });
        return parseInt(Math.round((doneEpisodes / project.episodes.length) * 100));
      }
    },
  },

  updated() {
    const mySVGsToInject = document.querySelectorAll("img.inject-me");
    SVGInjector(mySVGsToInject);
  },
  mounted() {
    const mySVGsToInject = document.querySelectorAll("img.inject-me");
    SVGInjector(mySVGsToInject);
  }
};
</script>

<template>
  <div class="wrapper">
    <navbar></navbar>
    <section class="projects container-fluid">
      <div class="projects__header">
        <div>
          <section class="projects__header__actions">
            <router-link class="projects__header__actions__add" to="/projects/create">
              <img :src="add" />
            </router-link>
          </section>
          <section class="searchbar">
            <input placeholder="Rechercher un projet" type="text" v-model="query" />
          </section>
        </div>
        <h1>Projets</h1>
      </div>
      <div class="projects__grid">
        <div class="projectcard" v-bind:key="project.id" v-for="project in projects">
          <div class="projectcard__header">
            <div class="projectcard__status projectcard__status--alert" v-if="project.alert && project.alert.length">
              <img :src="warning" />
            </div>
            <div class="projectcard__status projectcard__status--complete" v-else-if="project.progress == 100">
              <img :src="check" />
            </div>
            <div class="projectcard__status projectcard__status--wip" v-else>
              <img :src="progress" />
            </div>
            <div class="projectcard__infos">
              <h2 class="projectcard__name mb0">{{ project.name }}</h2>
              <div class="projectcard__progress">
                <span :class="{ 'projectcard__progress__pill--done': episode.status === 'COMPLETED' }"
                  class="projectcard__progress__pill" v-bind:key="episode.id" v-for="episode in project.episodes">{{
    episode.index
}}</span>
                <div class="projectcard__progress__outer">
                  <div :style="'width: ' + getProjectProgress(project) + '%'" class="projectcard__progress__inner">
                  </div>
                </div>
                <p>Progression : {{ getProjectProgress(project) }}%</p>
              </div>
            </div>
          </div>
          <!--div class="text--orange projectcard__alert">{{ project.alert }}</div>
          <div class="projectcard__flags">
            <span :class="'projectcard__flag--' + translation.status" class="projectcard__flag"
              v-bind:key="translation.language" v-for="translation in getActiveTranslations(project.translations)">
              <img :src="'/dist/img/' + translation.language + '.svg'" />
              <span class="projectcard__flag__check" v-if="translation.status == 'complete'">
                <img :src="check" />
              </span>
            </span>
          </div-->
          <router-link :to="'/projects/' + project.id" class="btn btn--ghost btn--block mtm">
            <span>
              Voir le projet
              <figure class="btn__icon">
                <img :src="arrow" class="inject-me" />
              </figure>
            </span>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

