
<style scoped lang="scss">
@import "@scss/app.scss";

.loader {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 2000;
  background: rgba(#fff, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
</style>
<script>
import Api from "./../api";
import Axios from "axios";
import loader from "./../../img/loading dots.svg";
import check from "./../../img/check white.svg";
export default {
  data: function () {
    return {
      name: null,
      workflows: null,
      currentWorkflowId: null,
      managerId: null,
      error: null,
      step: 1,
      loader,
      loading: false,
      addNbEpisodes: 1,
      currentEpisodeIndex: 1,
      episodes: [
        {
          index: 1,
          limitDate: null
        }
      ],
      users: null,
      roles: null,
      roleAttributions: []
    };
  },
  created() {
    Api.send("api/workflows")
      .then(ret => {
        let workflows = [];
        ret.data.forEach(workflow => {
          let preview = [...workflow.tasks];
          workflow.preview = preview.splice(0, 4);
          workflows.push(workflow);
        });
        this.workflows = workflows;
      })
      .catch(err => console.log(err));

    Api.send("api/users")
      .then(ret => {
        let users = [];
        ret.data.forEach(user => {
          users.push(user);
        });
        this.users = users;
      })
      .catch(err => console.log(err));
  },
  watch: {
    currentWorkflowId(newValue, oldValue) {
      Api.send("api/workflows/" + newValue + "/roles")
        .then(ret => {
          let roles = [];
          ret.data.forEach(role => {
            roles.push(role);
          });
          this.roles = roles;
        })
        .catch(err => console.log(err));
    },
    error(newValue, oldValue) {
      window.setTimeout(() => {
        this.error = null;
      }, 2000);
    }
  },
  methods: {
    addEpisodes() {
      for (let i = 1; i <= this.addNbEpisodes; i++) {
        let len = this.episodes.push({
          index: this.episodes.length + 1,
          limitDate: null
        });
        this.$refs.confirmadd.innerText = "Épisode(s) ajouté(s)";
        window.setTimeout(() => {
          this.$refs.confirmadd.innerText = "";
        }, 2000);
      }
    },
    deleteEpisode(index) {
      let key = _.findIndex(this.episodes, episode => {
        return episode.index == index;
      });
      this.episodes.splice(key, 1);
      let newindex = 1;
      _.forEach(this.episodes, episode => {
        episode.index = newindex;
        newindex++;
      });
      if (
        this.currentEpisodeIndex > 0 &&
        this.currentEpisodeIndex - 1 <= newindex
      ) {
        this.currentEpisodeIndex--;
      } else {
        this.currentEpisodeIndex = 1;
      }
      // let episodes = this.episodes;
      // _.remove(episodes, episode => {
      //   return episode.index == index;
      // });
      // this.$set(this, "episodes", episodes);
    },
    addUserRole(roleId, userId) {
      const user = _.find(this.users, user => {
        return user.id == userId;
      });

      const existing = _.find(this.roleAttributions, attr => {
        return attr.roleId == roleId;
      });

      if (!existing) {
        this.roleAttributions.push({
          roleId,
          users: [userId]
        });
      } else {
        if (existing.users.indexOf(userId) < 0) {
          existing.users.push(userId);
        }
      }

      // if (!this.roleAttributions[roleId]) {
      //   this.$set(this.roleAttributions, roleId, {});
      // }
      // this.$set(this.roleAttributions[roleId], userId, user);

      //this.roleAttributions = _.uniq(this.roleAttributions);
      // if (indexOf(roleId, this.roleAttributions) >= 0) {
      //   this.roleAttributions[roleId].push(userId);
      //   this.roleAttributions = _.uniq(this.roleAttributions);
      // } else {
      //   this.roleAttributions[roleId] = [userId];
      // }
    },
    getRoleUsers(roleId) {
      let users = [];
      let role = _.find(this.roleAttributions, attr => {
        return attr.roleId == roleId;
      });
      if (role) {
        role.users.forEach(id => {
          users.push(
            _.find(this.users, user => {
              return user.id == id;
            })
          );
        });
      }
      return users;
    },
    createProject() {
      this.loading = true;
      this.error = null;
      if (!this.currentWorkflowId) {
        this.error = "Vous devez sélectionner un workflow";
        this.loading = false;
      }
      if (!this.name) {
        this.error = "Vous devez donnez un nom à votre projet";
        this.loading = false;
      }
      if (!this.error) {
        let data = new FormData();
        data.append("name", this.name);
        data.append("workflowId", this.currentWorkflowId);
        data.append("roles", JSON.stringify(this.roleAttributions));
        data.append("episodes", JSON.stringify(this.episodes));
        data.append("nbEpisodes", this.episodes.length);

        Api.post("api/projects/create", {
          name: this.name,
          workflowId: this.currentWorkflowId,
          roles: JSON.stringify(this.roleAttributions),
          episodes: JSON.stringify(this.episodes),
          nbEpisodes: this.episodes.length,
          managerId: this.managerId
        })
          .then(ret => {
            this.$refs.confirmcreate.innerText =
              "Projet créé avec succès. Chargement de vos données";
            Api.refreshData()
              .then(ret => {
                this.$store.commit("projects", ret.data.projects);
                this.$router.push("/projects");
              })
              .catch(e => {
                console.log(e);
                this.error = e.response.data;
              });
          })
          .catch(e => {
            this.error = e.response.data;
            this.loading = false;
          });

        //this.$store.commit("addProject", project);
        //this.$router.push("/projects");
        // let form = new FormData();
        // form.append("project", project);
        // // Send API Request
        // Axios.post(Api.buildUrl("projects/create/"), project)
        //   .then(() => {
        //     // Create
        //     this.$store.commit("addProject", project);
        //     this.$router.push("/projects");
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
      }
    }
  }
};
</script>
<template>
  <div class="wrapper">
    <div class="loader" v-if="loading">
      <img :src="loader" width="32" />
      <p class="mtm text--large" ref="confirmcreate"></p>
    </div>
    <navbar back="true"></navbar>
    <p class="text--error text--center text--large mbm" v-if="error">{{ error }}</p>
    <h1>Créer un projet</h1>
    <ul class="breadcrumb">
      <li :class="{ 'active': step == 1 }" @click="step = 1">Infos</li>
      <li :class="{ 'active': step == 2 }" @click="step = 2">Rôles par défaut</li>
      <li :class="{ 'active': step == 3 }" @click="step = 3">Épisodes</li>
    </ul>

    <section class="createproject">
      <section v-if="step == 1">
        <div class="myl">
          <h3>Choisissez un nom pour votre projet</h3>
          <input placeholder="Cliquez ici pour éditer le nom" type="text" v-model="name" />
        </div>
        <div class="text--center mtm" v-if="!workflows">
          <img :src="loader" width="32" />
        </div>
        <div v-else>
          <h3>Sélectionnez un workflow</h3>
          <div class="createproject__workflows" v-if="workflows.length">
            <div :class="{
              active: workflow.id == currentWorkflowId,
              inactive: currentWorkflowId && workflow.id != currentWorkflowId
            }" @click="currentWorkflowId = workflow.id" v-bind:key="workflow.id" v-for="workflow in workflows">
              <b>{{ workflow.name }}</b>
              <br />
              {{ workflow.tasks.length }} étapes
              <ul class="text--muted">
                <li v-bind:key="task.id" v-for="task in workflow.preview">{{ task.name }}</li>
                <li>...</li>
              </ul>
            </div>
          </div>
          <div v-else>
            <p>Aucun workflow n'a pu être chargé, veuillez contacter votre chargé de compte Allskreen.</p>
          </div>
        </div>
        <button :disabled="!this.currentWorkflowId || !this.name" @click="step = 2" class="btn mtl">Continuer</button>
      </section>

      <section v-if="step == 2">
        <div class="text--center mtm" v-if="!roles || !users">
          <img :src="loader" v-if="currentWorkflowId" width="32" />
          <p class="mtm" v-else>Veuillez sélectionner un workflow</p>
        </div>
        <div class="mw600" v-else>
          <h3>Attribuez les rôles du projet</h3>
          <div class="createproject__roles" v-if="roles.length && users.length">
            <div class="input">
              <h4>Responsable du projet</h4>
              <div class="select">
                <select v-model="managerId">
                  <option value></option>
                  <option :key="user.id" :value="user.id" v-for="user in users">{{ user.username }} ({{ user.email }})
                  </option>
                </select>
              </div>
            </div>
            <hr class="mym" />
            <div :key="role.id" class="grid-2 mbm" v-for="role in roles">
              <div>
                <h4 class="mb0">{{ role.name }}</h4>
                <div>
                  <span class="createproject__roles__user" v-bind:key="user.id" v-for="user in getRoleUsers(role.id)">{{
                    user.username
                  }}</span>
                </div>
              </div>
              <div class="select select--add" style="width: 50px;">
                <select @input="addUserRole(role.id, $event.target.value); $event.target.value = ''">
                  <option selected value></option>
                  <option :key="user.id" :value="user.id" v-for="user in users">{{ user.username }} ({{ user.email }})
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Aucun rôle n'a pu être chargé, veuillez contacter votre chargé de compte Allskreen.</p>
          </div>
          <button @click="step = 3" class="btn mtl">Continuer</button>
        </div>
      </section>

      <section v-if="step == 3">
        <div class="createproject__episodes__header">
          <div class="select mw400">
            <select v-model="currentEpisodeIndex">
              <option></option>
              <option :value="episode.index" v-bind:key="episode.index" v-for="episode in episodes">Épisode
                {{ episode.index }}</option>
            </select>
          </div>
          <div class="createproject__episodes__header__add">
            Ajouter
            <input @click="$event.target.select()" type="text" v-model="addNbEpisodes" /> épisode
            <span @click="addEpisodes()" class="createproject__episodes__header__add__btn">+</span>
            <span class="confirm mls" ref="confirmadd"></span>
          </div>
        </div>
        <div v-bind:key="episode.index" v-for="episode in episodes" v-show="episode.index == currentEpisodeIndex">
          <div class="mw400">
            <h2>Épisode {{ episode.index }}</h2>
            <div class="input">
              <label>
                Date limite de réalisation
                <span class="text--muted">&nbsp;(optionnel)</span>
              </label>
              <input type="date" v-model="episode.limitDate" />
            </div>
          </div>
          <a @click.stop.prevent="deleteEpisode(episode.index)" class="text--error" href="#">Supprimer l'épisode</a>
        </div>
        <button :disabled="loading" @click="createProject()" class="btn mtl">Enregistrer le projet</button>
      </section>
    </section>
  </div>
</template>

