<style lang="scss">
@import "@scss/app.scss";
</style>

<script>
import logo from "./../../img/icon factory small.png";
import arrow from "./../../img/arrow right.svg";
import avatar from "./../../img/avatar batman.svg";

export default {
  data: function () {
    return {
      logo,
      arrow,
      avatar,
      showUserMenu: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login/");
    }
  },
  props: ["back"]
};
</script>
<template>
  <nav class="navbar">
    <div>
      <a href="#" class="navbar__back" @click="$router.back()">
        <img :src="arrow" class="inject-me">
      </a>
      <img :src="logo" class="navbar__logo" @click="$router.push('/workflows')">
    </div>
    <div></div>
    <div>
      <div class="navbar__user">
        <span class="navbar__user__icon" @click="showUserMenu = !showUserMenu">
          <img :src="avatar">
        </span>
        <div class="navbar__user__menu" v-show="showUserMenu">
          <p class="text--center">
            {{ user.name }}
            <br>
            <small>{{ user.email }}</small>
          </p>
          <button class="btn btn--ghost" @click="logout">Déconnexion</button>
        </div>
      </div>
    </div>
  </nav>
</template>

