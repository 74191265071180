<style lang="scss">
@import "@scss/app.scss";
</style>

<script>
import _ from "lodash";
import loader from "./../../img/loading.svg";
import SVGInjector from "svg-injector";
import Api from "./../api.js";
import roles_management from "./Roles__Management.vue";

export default {
  data: function () {
    console.log("tadaaaaaaa!!!");
    return {
      tab: "roles",
      loader,
    };
  },
  props: ["project"],
  /* components: {
    roles_management
  }*/
};
</script>

<template>
  <div class="wrapper">
    <navbar back="true"></navbar>
    <h1>Réglages projet</h1>

    <div class="settings">
      <div class="settings__sidebar">
        <ul>
          <li :class="{ active: tab == 'infos' }" @click="tab = 'infos'">Général</li>
          <li :class="{ active: tab == 'roles' }" @click="tab = 'roles'">Gestion des rôles</li>
        </ul>
      </div>
      <div class="settings__content">
        <section v-show="tab == 'roles'">
          <roles_management :project="project"></roles_management>
        </section>
      </div>
    </div>
  </div>
</template>
