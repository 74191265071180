<script>
import Navbar from "@components/Navbar.vue";
//import roles_management from "@components/Roles__Management.vue";

export default {
  components: {
    Navbar,
    //roles_management
  },
};
</script>


<template>
  <Navbar />
  <router-view />
</template>