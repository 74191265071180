<script>
import loader from "./../../img/loading dots white.svg";
import Api from "./../api.js";

export default {
  data: function () {
    return {
      loading: false,
      loader
    };
  },
  props: ['data'],
  created() {

  },
  methods: {
    getDownloadLink() {
      this.loading = true;
      const id = this.data.idPhotoshopFile;
      Api.get('api/photoshopfiles/' + id).then(ret => {
        let url = ret.data.url;
        window.open(url);
        this.loading = false;
      });
    }
  },
};
</script>
<template>
  <div class="filedownload text--center" v-if="this.data.idPhotoshopFile >= 0">
    <p>Télécharger le template Photoshop</p>
    <a :class="{'btn--loading':loading}" @click="getDownloadLink()" class="btn">
      <span>Télécharger</span>
      <span>
        <img :src="loader" />
      </span>
    </a>
  </div>
</template>