<script>
import Api from "../api.js";
export default {
    data() {
        return {
            managerId: null,
            roles: null,
            users: null,
            error: false

        }
    },
    props: ["project"],
    created() {
        if (this.project) {
            this.managerId = this.project.manager.id;
            let roles = [];
            this.project.roles.forEach(projectRole => {
                //création des roles
                let role = Object.assign({}, projectRole);
                if (!role.users) {
                    role.users = [];
                }
                //affectation des utilisateurs
                this.project.UserProjectRoles.forEach((UserProjectRole) => {
                    if (UserProjectRole.project_role.id == projectRole.id)
                        role.users.push(UserProjectRole.user);
                });
                roles.push(role);
            });
            this.roles = roles;
            //recherche de la liste des utilisateurs
            Api.send("api/users")
                .then(ret => {
                    let users = [];
                    ret.data.forEach(user => {
                        users.push(user);
                    });
                    if (!users.length) {
                        this.error = true;
                    }
                    this.users = users;
                })
                .catch(err => console.log(err));
        }
    },
    watch: {
        /* project() {
             console.log("watch");
             this.managerId = this.project.manager.id;
             this.roles = this.project.roles;
         }*/
    },
    methods: {
        addUserRole(roleId, userId) {
            //recherche de l'existence de l'utilisateur à ajouter 
            let user = _.find(this.users, user => {
                return user.id == userId;
            });

            //Recherche de l'existence du role
            let role = _.find(this.roles, role => {
                return role.id == roleId;
            });

            let isExist = _.find(role.users, user => {
                return user.id == userId;
            });

            //si il n'est pas déjà présent on l'ajoute
            if (!isExist) {
                this.roles.forEach(role => {
                    if (role.id == roleId) {
                        role.users.push(user);
                    }
                });
            }
        },
        deleteUserRole(roleId, userId) {
            this.roles.forEach(role => {
                if (role.id == roleId) {
                    role.users = role.users.filter((item) => {
                        return item.id !== userId
                    })
                }
            });
        },
        getRoleUsers(roleId) {
            let users = [];
            this.roles.forEach(role => {
                if (role.id == roleId) {
                    role.users.forEach(user => { users.push(user); })
                }
            });
            return users;
        },
        updateRoles() {
            Api.post("api/projects/" + this.project.id + '/updateroles', {
                managerId: this.managerId,
                roles: JSON.parse(JSON.stringify(this.roles))
            })
                .then(ret => {
                    this.$refs.success.innerText = "Rôles mis à jour.";
                })
                .catch(e => {
                    this.$refs.error.innerText = e;
                });
        }
    }
}
</script>

<template>
    <div class="mw600">
        <h3>Attribuez les rôles du projet</h3>
        <div v-if="error">
            <p>Aucun rôle n'a pu être chargé, veuillez contacter votre chargé de compte Allskreen.</p>
        </div>
        <div class="createproject__roles" v-else-if="roles && users">
            <div class="input">
                <h4>Responsable du projet</h4>
                <div class="select">
                    <select v-model="managerId">
                        <option value></option>
                        <option :key="user.id" :value="user.id" v-for="user in users">{{ user.username }} ({{
                            user.email
                        }})
                        </option>
                    </select>
                </div>
            </div>
            <hr class="mym" />
            <div :key="role.id" class="grid-2 mbm" v-for="role in roles">
                <div>
                    <h4 class="mb0">{{ role.name }}</h4>
                    <div>
                        <span class="createproject__roles__user" v-bind:key="user.id"
                            v-for="user in getRoleUsers(role.id)">{{
                                user.username
                            }}<span class="remove" @click="deleteUserRole(role.id, user.id);">X</span></span>
                    </div>
                </div>
                <div class="select select--add" style="width: 50px;">
                    <select @input="addUserRole(role.id, $event.target.value); $event.target.value = ''">
                        <option selected value></option>
                        <option :key="user.id" :value="user.id" v-for="user in users">{{ user.username }} ({{
                            user.email
                        }})
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <button @click="updateRoles()" class="btn">Mettre à jour</button>
        <p class="text text--error" ref="error"></p>
        <p class="text text--success" ref="success"></p>

    </div>
</template>

<style lang="scss" scoped>
@import "@scss/app.scss";
</style>