import { createStore } from "vuex";
import Api from "./api.js";
import router from "./router.js";

export const store = createStore({
  state: {
    user: {},
    projects: [],
    workflows: []
  },
  mutations: {
    user(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    projects(state, projects) {
      state.projects = projects;
      localStorage.setItem("projects", JSON.stringify(state.projects));
    },
    workflows(state, workflows) {
      state.workflows = workflows;
      localStorage.setItem("workflows", JSON.stringify(state.workflows));
    },
    addProject(state, project) {
      state.projects.push(project);
      localStorage.setItem("projects", JSON.stringify(state.projects));
    },
    loadState(state) {
      let user = localStorage.getItem("user");
      if (user && user != "undefined") {
        state.user = JSON.parse(user);
      }
      let projects = localStorage.getItem("projects");
      if (projects && projects != "undefined") {
        console.log("Loading projects from local storage");
        console.log(projects);
        state.projects = JSON.parse(projects);
      }
    },
    logout(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("projects");
      localStorage.removeItem("workflows");
      state.projects = {};
      state.user = {};
      state.workflows = {};
    }
  }
});
