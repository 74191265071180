<style lang="scss">
@import "@scss/app.scss";
</style>

<script>
import Api from "./../api.js";
import loader from "./../../img/loading.svg";

export default {
  data: function () {
    return {
      loader,
      loading: true,
      error: false,
      project: null
    };
  },
  props: ["id"],
  created() {
    this.refreshProject();
  },
  methods: {
    refreshProject() {
      this.loading = true;
      Api.send("api/projects/" + this.id)
        .then(ret => {
          window.setTimeout(() => {
            this.loading = false;
            this.project = ret.data;
          }, 100);
        })
        .catch(e => {
          this.loading = false;
          this.error = e.statusText;
        });
    },
  }
};
</script>
<template>
  <div>
    <div class="loading" v-show="loading">
      <img :src="loader" class="inject-me" />
      <!--<p>Chargement du projet</p>-->
    </div>
    <div class="loading" v-show="error">
      <p class="text--error">{{ error }}</p>
    </div>
    <router-view :error="error" :id="id" :project="project" @refreshProject="refreshProject()"></router-view>
  </div>
</template>

