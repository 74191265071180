
<style lang="scss">
@import "@scss/app.scss";
</style>

<script>
import icon from "./../../img/icon factory.png";
import bg from "./../../img/character working.png";
import api from "../api.js";

export default {
  data: function () {
    return {
      icon,
      email: "",
      password: "",
      loading: false,
      error: null
    };
  },
  methods: {
    async login() {
      this.loading = true;
      api.post("login", {
        email: this.email,
        password: this.password
      })
        .then(ret => {
          console.log(ret.data);
          this.$store.commit("user", ret.data);
          api.sendParams("api/workflows").then(ret => {
            console.log(ret.data);
            this.$store.commit("workflows", ret.data);
            this.$router.push("/workflows");
          }).catch(error => {
            // @TODO Display Error Message
            console.log(error);
          });
          /*api.sendParams("api/projects", {
            page: 0,
            len: 6
          })
            .then(ret => {
              this.$store.commit("projects", ret.data);
              this.$router.push("/projects");
              console.log(ret.data);
            })
            .catch(error => {
              // @TODO Display Error Message
              console.log(error);
              this.loading = false;
              this.error = error.response.data.message;
            });*/
        })
        .catch(error => {
          // @TODO Display Error Message
          this.loading = false;
          this.error = error.response.data.message;
        });
    }
  }
};
</script>

<template>
  <section>
    <div id="login">
      <form method="post" v-on:submit.prevent="login">
        <img :src="icon" class="mbs" width="96" />
        <h1 class="text--left">
          <strong>Bienvenue</strong>,
          <span>
            <br />commençons par se connecter !
          </span>
        </h1>
        <div v-if="error">
          <p class="text--error">{{ error }}</p>
        </div>
        <div class="input">
          <label for="email">Email</label>
          <input id="email" name="email" placeholder="xyz@gmail.com" required type="email" v-model="email" />
        </div>
        <div class="input">
          <label for="password">Mot de passe</label>
          <input id="password" placeholder="Entrez votre mot de passe" required type="password" v-model="password" />
        </div>
        <input :disabled="loading" class="btn" type="submit" value="Se Connecter" />
        <div class="text--center pam">
          <a href="#">Mot de passe oublié</a>
        </div>
      </form>
    </div>
  </section>
</template>
