import { createRouter, createWebHistory } from 'vue-router';

import Login from './components/Login.vue';
import Projects from './components/Projects.vue';
import Projects_workflow from './components/Projects_workflow.vue';
import Workflows from './components/Workflows.vue';
import Project from './components/Project.vue';
import CreateProject from './components/CreateProject.vue';
import Project__Dashboard from './components/Project__Dashboard.vue';
import Project__Settings from './components/Project__Settings.vue';

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/projects",
    component: Projects
  },
  {
    path: "/workflows",
    component: Workflows
  },
  {
    path: "/projects_workflow/:id/:name",
    component: Projects_workflow,
    props: true
  },
  {
    path: "/projects/create",
    component: CreateProject
  },
  {
    path: "/projects/:id",
    component: Project,
    props: true,
    children: [
      {
        path: "",
        component: Project__Dashboard
      },
      {
        path: "settings",
        component: Project__Settings
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
