<style lang="scss">
@import "@scss/app.scss";
</style>

<script>
import arrow from "./../../img/arrow right.svg";
export default {
    data: function () {
        return {
            arrow
        };
    },
    computed: {
        workflows() {
            let workflows = this.$store.state.workflows;
            return workflows;
        },

    },
    methods: {

    },

    updated() {

    },
    mounted() {

    }
};
</script>

<template>
    <div class="wrapper">
        <navbar></navbar>
        <section class="workflows container-fluid">
            <div class="workflows__header">
                <h1>Workflows</h1>
            </div>
            <div class="workflows__grid">
                <div class="workflowcard" v-bind:key="workflow.id" v-for="workflow in workflows">
                    <div class="workflowcard__header">
                        <div class="workflowcard__infos">
                            <h2 class="workflowcard__name mb0">{{ workflow.name }}</h2>
                        </div>
                    </div>

                    <router-link :to="'/projects_workflow/' + workflow.id + '/' + workflow.name"
                        class="btn btn--ghost btn--block mtm">
                        <span>
                            Voir les projets
                            <figure class="btn__icon">
                                <img :src="arrow" class="inject-me" />
                            </figure>
                        </span>
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>

