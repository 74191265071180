import axios from "axios";
import _ from "lodash";
import router from "./router.js";

const API_URL = "https://api.faktory2.allskreen.com";
//const API_URL = "http://127.0.0.1:80";
Array.prototype.randomElement = function () {
  return this[Math.floor(Math.random() * this.length)];
};

export default {
  baseUrl: API_URL,
  fakeReturn: false,

  buildUrl(endpoint) {
    return this.baseUrl + "/" + endpoint;
  },

  async send(endpoint, data, method = "get") {
    if (this.fakeReturn) {
      // Return fake data
      return this.sendFakeRequest(endpoint, data);
    } else {
      const date = new Date();
      const min = date.getMinutes() * 3362 + 10952;
      const apikey = btoa("allskreen") + "." + btoa("api key here") + "." + min;

      let user = JSON.parse(localStorage.getItem("user"));
      let headers = {
        apikey,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
        Authorization: null,
      };
      if (user) {
        headers.Authorization = "Bearer " + user.token;
      }

      return new Promise((resolve, reject) => {
        if (method == "get") {
          axios
            .get(this.baseUrl + "/" + endpoint, {
              headers,
            })
            .then(function (ret) {
              resolve(ret);
            })
            .catch((error) => {
              if (error.response && error.response.status == 401) {
                this.refreshToken()
                  .then(() => {
                    this.send(endpoint, data, method);
                  })
                  .catch((e) => {
                    reject(e);
                  });
              } else {
                reject(error);
              }
            });
        } else {
          axios
            .post(this.baseUrl + "/" + endpoint, data, {
              headers,
            })
            .then(function (ret) {
              resolve(ret);
            })
            .catch((error) => {
              if (error.response && error.response.status == 401 && endpoint !== "login") {
                this.refreshToken()
                  .then(() => {
                    this.send(endpoint, data, method);
                  })
                  .catch((e) => {
                    reject(e);
                  });
              } else {
                reject(error);
              }
            });
        }
      });
    }
  },

  async sendParams(endpoint, params, method = "get") {
    if (this.fakeReturn) {
      // Return fake data
      return this.sendFakeRequest(endpoint, data);
    } else {
      const date = new Date();
      const min = date.getMinutes() * 3362 + 10952;
      const apikey = btoa("allskreen") + "." + btoa("GDwLZLDw84mt9MS3mmlzRHygq7RcyR4otZ7WemYX") + "." + min;

      let user = JSON.parse(localStorage.getItem("user"));
      let headers = {
        apikey,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
        Authorization: null,
      };
      if (user) {
        headers.Authorization = "Bearer " + user.token;
      }

      return new Promise((resolve, reject) => {
        if (method == "get") {
          axios
            .get(this.baseUrl + "/" + endpoint, {
              headers,
              params,
            })
            .then(function (ret) {
              resolve(ret);
            })
            .catch((error) => {
              if (error.response && error.response.status == 401) {
                this.refreshToken()
                  .then(() => {
                    this.send(endpoint, params, method);
                  })
                  .catch((e) => {
                    reject(e);
                  });
              } else {
                reject(error);
              }
            });
        } else {
          axios
            .post(this.baseUrl + "/" + endpoint, data, {
              headers,
            })
            .then(function (ret) {
              resolve(ret);
            })
            .catch((error) => {
              if (error.response && error.response.status == 401 && endpoint !== "login") {
                this.refreshToken()
                  .then(() => {
                    this.send(endpoint, data, method);
                  })
                  .catch((e) => {
                    reject(e);
                  });
              } else {
                reject(error);
              }
            });
        }
      });
    }
  },

  async get(endpoint) {
    return this.send(endpoint, null);
  },

  async post(endpoint, data) {
    return this.send(endpoint, data, "post");
  },

  async refreshData() {
    if (this.fakeReturn) {
      // Return fake data
      //return this.sendFakeRequest("login");
    } else {
      // Return real data
      return this.get("api/projects");
    }
  },

  async refreshToken() {
    return new Promise((resolve, reject) => {
      const date = new Date();
      const min = date.getMinutes() * 3362 + 10952;
      const apikey = btoa("allskreen") + "." + btoa("GDwLZLDw84mt9MS3mmlzRHygq7RcyR4otZ7WemYX") + "." + min;

      let user = JSON.parse(localStorage.getItem("user"));
      console.log(user);
      let headers = {
        apikey,
        "Content-Type": "application/json",
      };
      let data = {
        refreshToken: user.refreshToken,
      };
      axios
        .post(this.baseUrl + "/refresh", data, { headers })
        .then((ret) => {
          user = ret.data;
          localStorage.setItem("user", JSON.stringify(user));
          resolve();
        })
        .catch((e) => {
          reject();
        });
    });
  },

  // sendFakeRequest(endpoint) {
  //   return new Promise((resolve, reject) => {
  //     if (endpoint.match(/login/)) {
  //       const data = {
  //         token: "demo.jwt",
  //         email: "vermichou@gmail.com",
  //         name: "Michel Vermeulen"
  //       };
  //       resolve(this.returnSuccess(data));
  //     }
  //     if (endpoint == "projects/") {
  //       const data = [
  //         {
  //           id: 1,
  //           name: "et quaerat",
  //           progress: 100,
  //           active: 1,
  //           alert: "",
  //           nbEpisodes: 4,
  //           translations: []
  //         },
  //         {
  //           id: 2,
  //           name: "qui quia",
  //           progress: 89,
  //           active: 1,
  //           alert: "",
  //           nbEpisodes: 6,
  //           translations: [
  //             {
  //               language: "en",
  //               status: "complete"
  //             },
  //             {
  //               language: "nl",
  //               status: "inactive"
  //             },
  //             {
  //               language: "de",
  //               status: "pending"
  //             },
  //             {
  //               language: "es",
  //               status: "inactive"
  //             }
  //           ]
  //         }
  //       ];
  //       resolve(this.returnSuccess(data));
  //     }
  //     if (endpoint == "workflows/") {
  //       const data = [
  //         {
  //           name: "Mon workflow",
  //           id: 1,
  //           nbSteps: 100,
  //           tasks: ["Etape 1", "Etape 2"]
  //         }
  //       ];
  //       resolve(this.returnSuccess(data));
  //     }
  //     if (endpoint.match(/projects\/[0-9]+/)) {
  //       const taskStatuses = ["complete", "pending", "locked", "warning"];
  //       const tasks = ["Ranger la vaisselle", "Faire le ménage", "Vidanger la voiture", "Déposer les enfants", "Essorer la salade"];
  //       let date = new Date();

  //       const data = {
  //         name: "Super Projet",
  //         episodes: [],
  //         roles: [],
  //         nextPublishDate: Math.round(Math.random()) ? _.padStart(date.getDate(), 2, 0) + "/" + _.padStart(date.getMonth() + 1, 2, 0) + "/" + date.getFullYear() : null,
  //         workflow: {
  //           name: "Workflow Super Compliqué",
  //           tasks: []
  //         }
  //       };

  //       for (let i = 0; i < _.random(1, 2); i++) {
  //         data.workflow.tasks.push({
  //           id: _.random(1, 50000),
  //           manager: faker.name.firstName() + " " + faker.name.firstName(),
  //           name: tasks.randomElement(),
  //           template: [
  //             {
  //               name: "comments",
  //               data: null
  //             },
  //             {
  //               name: "fileupload",
  //               data: null
  //             }
  //           ]
  //         });
  //       }

  //       // Add episodes
  //       for (let i = 0; i < _.random(1, 2); i++) {
  //         let date = new Date();
  //         let episodeStatus = "pending";
  //         let tasks = JSON.parse(JSON.stringify(data.workflow.tasks));
  //         _.forEach(tasks, task => {
  //           if (episodeStatus !== "complete") {
  //             task.status = taskStatuses.randomElement();
  //           } else {
  //             task.status = "complete";
  //           }
  //           if (task.status === "warning") {
  //             episodeStatus = "warning";
  //           }

  //           let comments = [];
  //           for (let i = 0; i < _.random(1, 2); i++) {
  //             comments.push({
  //               author: faker.name.firstName() + " " + faker.name.lastName(),
  //               content: faker.lorem.sentences(),
  //               date: "Hier à 12h00"
  //             });
  //           }
  //           task.template[0].data = {
  //             comments
  //           };
  //         });
  //         date.setDate(date.getDate() + Math.round(Math.random() * 30));
  //         data.episodes.push({
  //           id: i,
  //           index: i + 1,
  //           // Fill next publish date to show a warning on the project page
  //           publishDate: Math.round(Math.random()) ? date : null,
  //           status: episodeStatus,
  //           tasks
  //         });
  //       }

  //       console.log(data);
  //       resolve(this.returnSuccess(data));
  //     }

  //     reject(this.returnNotFound());
  //   });
  // },

  returnSuccess(data) {
    return {
      data,

      // `status` is the HTTP status code from the server response
      status: 200,

      // `statusText` is the HTTP status message from the server response
      statusText: "OK",

      // `headers` the headers that the server responded with
      // All header names are lower cased
      headers: {},

      // `config` is the config that was provided to `axios` for the request
      config: {},

      // `request` is the request that generated this response
      // It is the last ClientRequest instance in node.js (in redirects)
      // and an XMLHttpRequest instance the browser
      request: {},
    };
  },

  returnNotFound(data) {
    return {
      data,

      // `status` is the HTTP status code from the server response
      status: 404,

      // `statusText` is the HTTP status message from the server response
      statusText: "Not Found",

      // `headers` the headers that the server responded with
      // All header names are lower cased
      headers: {},

      // `config` is the config that was provided to `axios` for the request
      config: {},

      // `request` is the request that generated this response
      // It is the last ClientRequest instance in node.js (in redirects)
      // and an XMLHttpRequest instance the browser
      request: {},
    };
  },
};
