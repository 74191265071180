<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/1.0.18/vue.min.js"></script> -->
<style lang="scss">
@import "@scss/app.scss";
</style>
<script>
// Load component dynamically : https://stackoverflow.com/questions/54719849/vuejs-create-component-programmatically-by-name

import _ from "lodash";
import stickybits from "stickybits";
import Api from "./../api.js";
import Axios from "axios";
import SVGInjector from "svg-injector";
import loaderImg from "./../../img/loading.svg";

// Import Widgets
import PHOTOSHOP_UPLOAD from "./../widgets/PHOTOSHOP_UPLOAD.vue";
import PHOTOSHOP_DOWNLOAD from "./../widgets/PHOTOSHOP_DOWNLOAD.vue";

import lock from "./../../img/lock secure.svg";
import warning from "./../../img/flag warning.svg";
import check from "./../../img/icon check checkmark done.svg";
import close from "./../../img/close cross error.svg";
import calendar from "./../../img/calendar.svg";

export default {
  data: function () {
    return {
      error: false,
      lock,
      close,
      loading: false,
      calendar,
      check,
      loaderImg,
      warning,
      currentEpisode: null,
      currentTask: null,
      formData: null,
      canUpdate: false,
      showRoles: true,
      currentWidgets: [],
      showWidgetPanel: false,
      mutableProject: null,
    };
  },
  components: {
    PHOTOSHOP_UPLOAD: PHOTOSHOP_UPLOAD,
    PHOTOSHOP_DOWNLOAD: PHOTOSHOP_DOWNLOAD,
  },
  props: ["id", "project"],
  created() {
    this.formData = new FormData();
    this.canUpdate = false;
    if (this.project && this.project.episodes.length) {
      this.mutableProject = this.project;
      this.currentEpisode = _.first(this.mutable.episodes);
    }
  },
  watch: {
    project(value) {
      this.mutableProject = this.project;
      if (this.mutableProject && this.mutableProject.episodes.length) {
        this.currentEpisode = _.first(this.mutableProject.episodes);
      }
    },
    currentEpisode() {
      this.currentTaskId = null;
    },
  },
  mounted() {
    const mySVGsToInject = document.querySelectorAll("img.inject-me");
    SVGInjector(mySVGsToInject);
    stickybits(".project__episode__taskcontent", { useStickyClasses: true });
  },
  // updated() {
  //   const mySVGsToInject = document.querySelectorAll("img.inject-me");
  //   SVGInjector(mySVGsToInject);
  //   if (this.mutableProject && this.mutableProject.episodes.length) {
  //     this.currentEpisode = _.first(this.mutableProject.episodes);
  //   }
  // },
  methods: {
    getTaskStyle(task) {
      if (task.project_task) {
        return "grid-column: 1 / span " + this.mutableProject.episodes.length;
      }
    },
    openTask(task) {
      this.currentTask = task;
      this.currentWidgets = [];
      task.widgets.forEach((widget) => {
        this.currentWidgets.push(widget);
      });
      this.showWidgetPanel = true;
    },
    getExtension(link) {
      return link.split("?")[0].split(".").slice(-1)[0].toUpperCase();
    },
    validateCurrentTask() {
      this.loading = true;
      Api.post("api/tasks/" + this.currentTask.id + "/complete")
        .then(() => {
          this.showWidgetPanel = false;
          this.loading = false;
          this.$emit("refreshProject");
        })
        .catch((e) => {
          this.loading = false;
          this.$refs.error.innerText = e;
        });
    },
    refuseCurrentTask() {
      this.loading = true;
      Api.post("api/tasks/" + this.currentTask.id + "/refuse")
        .then(() => {
          this.showWidgetPanel = false;
          this.loading = false;
          this.$emit("refreshProject");
        })
        .catch((e) => {
          this.loading = false;
          this.$refs.error.innerText = e;
        });
    },
    createToken(id) {
      this.loading = true;
      Api.post("api/episode/token/" + id)
        .then(() => {
          this.showWidgetPanel = false;
          this.loading = false;
          this.$emit("refreshProject");
        })
        .catch((e) => {
          this.loading = false;
          this.$refs.error.innerText = e;
        });
    },
    setCurrentTaskInProgress() {
      this.loading = true;
      Api.post("api/tasks/" + this.currentTask.id + "/setinprogress")
        .then(() => {
          this.showWidgetPanel = false;
          this.loading = false;
          this.$emit("refreshProject");
        })
        .catch((e) => {
          this.loading = false;
          this.$refs.error.innerText = e;
        });
    },
  },
};
</script>
<template>
  <div class="wrapper">
    <div class="loading" v-show="loading">
      <img :src="loaderImg" class="inject-me" />
    </div>
    <navbar back="true"></navbar>

    <div class="project" v-if="mutableProject">
      <h1 class="text--left">
        {{ mutableProject.name }}
        <router-link :to="'/projects/' + id + '/settings'" class="title__link">Modifier</router-link>
      </h1>

      <div :style="'grid-template-columns: repeat(' + mutableProject.episodes.length + ',200px)'" class="project__episodes" v-if="project && mutableProject.episodes.length">
        <div :key="episode.id" class="project__episode" v-for="episode in mutableProject.episodes">Episode {{ episode.index }}</div>
      </div>

      <div :key="task.id" :style="'grid-template-columns: repeat(' + mutableProject.episodes.length + ',200px)'" class="project__episode__tasks" v-for="task in mutableProject.projectTasks">
        <div :class="'project__episode__task--' + task.status" :style="getTaskStyle(task)" @click="openTask(task)" class="project__episode__task">{{ task.name }}</div>
        <!-- <div :class="'project__episode__task--' + getTaskStatus(task)" :key="task.id + '' + task.episodeId" :style="getTaskStyle(task.projectTask)" @click="openTask(task)" class="project__episode__task" v-for="task in getTasks()">{{task.name}}</div> -->
      </div>

      <!--ligne EPISODES -->
      <div :style="'grid-template-columns: repeat(' + mutableProject.episodes.length + ',200px)'" class="project__episode__tasks">
        <div :key="episode.id" v-for="episode in mutableProject.episodes">
          <div :class="'project__episode__task--' + task.status" :key="task.id" :style="getTaskStyle(task)" @click="openTask(task)" class="project__episode__task" v-for="task in episode.episodeTasks">{{ task.name }}</div>
        </div>
      </div>
      <!-- FICHIER PSD -->
      <div :style="'grid-template-columns: repeat(' + mutableProject.episodes.length + ',200px)'" class="project__episodes__link" v-if="project && mutableProject.episodes.length">
        <div :key="episode.id" v-for="episode in mutableProject.episodes">
          <div v-if="episode.psd">
            <a :href="episode.psd">{{ getExtension(episode.psd) }}</a>
          </div>
          <div v-else>
            <a>-</a>
          </div>
        </div>
      </div>
      <!-- LIEN -->
      <div :style="'grid-template-columns: repeat(' + mutableProject.episodes.length + ',200px)'" class="project__episodes__link" v-if="project && mutableProject.episodes.length">
        <div :key="episode.id" v-for="episode in mutableProject.episodes">
          <div v-if="episode.token">
            <a :href="'https://ankama.allskreen.com/' + episode.token">Lien</a>
          </div>
          <div v-else>
            <div @click="createToken(episode.id)" style="cursor: pointer">Créer le lien</div>
          </div>
        </div>
      </div>
    </div>

    <!-- PANEL -->
    <div @click.self="showWidgetPanel = false" class="widgetpanel" v-if="showWidgetPanel">
      <div class="widgetpanel__content">
        <div v-if="currentTask.status != 'WAITING'">
          <component v-bind:data="widget.data" v-bind:is="widget.type" v-bind:key="widget.id" v-for="widget in currentWidgets"></component>
        </div>
        <p class="text--error text--center" ref="error"></p>
        <div class="text--center" v-if="currentTask.status == 'IN_PROGRESS'">
          <button @click="validateCurrentTask()" class="btn btn--success">Valider la tâche</button>
          <button @click="refuseCurrentTask()" class="btn" v-if="currentTask.status == 'WAITING'">Refuser la tâche</button>
        </div>
        <div class="text--center" v-if="currentTask.status == 'WAITING'">
          <button @click="setCurrentTaskInProgress()" class="btn">Débloquer la tâche</button>
        </div>
        <div class="text--center" v-if="currentTask.status == 'COMPLETED'">
          <button @click="refuseCurrentTask()" class="btn" v-if="currentTask.index == 6">Refuser la tâche</button>
        </div>
      </div>
    </div>
  </div>
</template>
