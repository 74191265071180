import { createApp, h } from "vue";

import Navbar from "./js/components/Navbar.vue";
import roles_management from "./js/components/Roles__Management.vue";
import Login from "./js/components/Login.vue";
import Projects from "./js/components/Projects.vue";
import App from "./App.vue";

import SVGInjector from "svg-injector";

import Api from "./js/api.js";
import router from "./js/router.js";
import { store } from "./js/store.js";

const app = createApp({
  router,
  store,
  components: {
    navbar: Navbar,
    roles_management: roles_management,
  },
  render: () => h(App),
  updated() {
    const mySVGsToInject = document.querySelectorAll("img.inject-me");
    SVGInjector(mySVGsToInject);
  },
  mounted() {
    const mySVGsToInject = document.querySelectorAll("img.inject-me");
    SVGInjector(mySVGsToInject);
  },
  created() {
    //this.$router.replace("/");
    this.$store.commit("loadState");
    if (!store.state.user || !store.state.user.email) {
      console.log("pas de données de user en store");
      console.log(store.state.data.user);
      //router.replace("/login");
    } else {
      Api.refreshData()
        .then((ret) => {
          store.commit("projects", ret.data.projects);
        })
        .catch((e) => {
          console.log(e);
        });
      //this.$router.replace("projects");
    }
  },
});

app.config.globalProperties.$filters = {
  capitalize: function (value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  },
};
app.component("roles_management", roles_management);
app.use(store);
app.use(router);

app.mount("#app");
