<script>
import upload from "./../../img/upload document.svg";

export default {
  data: function () {
    return {
      upload
    };
  },
  methods: {
    updateFile(event) {
      document.querySelector(".fileupload label").innerHTML =
        event.target.files[0].name;
      this.$emit("change", event.target.files[0]);
    }
  },
  updated() {
    document.querySelector(".fileupload label").innerHTML =
      "Envoyer un fichier";
  }
};
</script>
<template>
  <div class="fileupload">
    <img :src="upload" class="inject-me" />
    <input @change="updateFile($event)" type="file" />
    <span>Cliquez sur le bouton ci-dessous ou glissez-déposez un fichier</span>
    <label>Envoyer un fichier</label>
  </div>
</template>